<div class="heading d-none d-md-block">
</div>
<div class="container">
    <div class="row justify-content-center align-items-center">
        <div class="card profile-card">
            <div class="card-info">
                <div class="row">
                    <div class="col-md-9">
                        <h1 class="card-title">Alexander Dahl</h1>
                        <h4 class="card-subtitle text-muted">Data Engineer</h4>
                    </div>
                    <div class="resume col-md-3 d-none d-md-block">
                        <div class="btn-group float-lg-right">
                            <a class="resume-view btn btn-primary" href="/assets/Alexander_Dahl.pdf" target="_blank"
                                ngbPopover="View Resume" triggers="mouseenter:mouseleave">
                                Resume
                            </a>
                            <a class="resume-download btn btn-primary d-none d-sm-block"
                                href="/assets/Alexander_Dahl.pdf" download="" ngbPopover="Download Resume"
                                triggers="mouseenter:mouseleave">
                                <i class="fa fa-download" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="card-contact-info row">
                    <div class="col-md-7">
                        <ul class="info-list">
                            <li>
                                <i class="fa fa-graduation-cap"></i> M.Sc. in Computer Science and Logistics
                            </li>
                            <li>
                                <a href="mailto:alex@dahl.dev"><i class="fa fa-envelope"></i>
                                    alex@dahl.dev
                                </a>
                            </li>
                            <li>
                                <i class="fa fa-map-marker"></i> Stockholm
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-5">
                        <ul class="info-list">
                            <li>
                                <a href="https://github.com/aleda145" target="_blank">
                                    <i class="fa fa-github fa-2x" aria-hidden="true"></i> Github
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/dahlalexander/" target="_blank">
                                    <i class="fa fa-linkedin fa-2x" aria-hidden="true"></i> LinkedIn
                                </a>
                            </li>
                            <li class="d-md-none">
                                <hr />
                                <a class="btn btn-primary" href="/assets/Alexander_Dahl.pdf" target="_blank">
                                    Resume
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>