<div class="heading d-none d-md-block">
</div>
<div class="container">
    <div class="row justify-content-center align-items-center">
        <div class="card profile-card">
            <div class="card-info">
                <div class="row">
                    <div class="col-md-9">
                        <h1 class="card-title">Alexander Dahl</h1>
                        <h4 class="card-subtitle text-muted">Data Engineer</h4>
                    </div>
                    <div class="resume col-md-3 d-none d-md-block">
                        <div class="btn-group float-lg-right">
                            <a class="resume-view btn btn-primary" href="/assets/Alexander_Dahl.pdf" target="_blank"
                                ngbPopover="View Resume" triggers="mouseenter:mouseleave">
                                Resume
                            </a>
                            <a class="resume-download btn btn-primary d-none d-sm-block"
                                href="/assets/Alexander_Dahl.pdf" download="" ngbPopover="Download Resume"
                                triggers="mouseenter:mouseleave">
                                <i class="fa fa-download" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="card-contact-info row">
                    <div class="col-md-7">
                        <ul class="info-list">
                            <li>
                                <i class="fa fa-graduation-cap"></i> M.Sc. in Computer Science and Logistics
                            </li>
                            <li>
                                <a href="mailto:alex@dahl.dev"><i class="fa fa-envelope"></i>
                                    alex@dahl.dev
                                </a>
                            </li>
                            <li>
                                <i class="fa fa-map-marker"></i> Stockholm
                            </li>
                            <li>
                                <a href="https://dahl.dev/calendar" target="_blank">
                                 <i class="fa fa-calendar"></i> My Calendar
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-5">
                        <ul class="info-list">
                            <li>
                                <a href="https://github.com/aleda145" target="_blank">
                                    <i class="fa fa-github fa-2x" aria-hidden="true"></i> Github
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/dahlalexander/" target="_blank">
                                    <i class="fa fa-linkedin fa-2x" aria-hidden="true"></i> LinkedIn
                                </a>
                            </li>
                            <li>
                                <a href="https://blog.dahl.dev" target="_blank">
                                 <i class="fa fa-pencil"></i> My Blog
                                </a>
                            </li>
                            <li class="d-md-none">
                                <hr />
                                <a class="btn btn-primary" href="/assets/Alexander_Dahl.pdf" target="_blank">
                                    Resume
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row  d-flex justify-content-center align-items-center">
        <div class="col-md-10 about">
            <div class="about-heading">
                <h4>Hello human! </h4> <small>(bots are also welcome)</small>
            </div>
            <div>
                <p>My name is Alex and welcome to my internet home.
                    <del>I am currently on a sabbatical and just enjoying life.
                    I used to be a data engineer, and will probably be one
                    again in the future. </del>
                    I'm currently working as a data engineer at <a href="https://mentimeter.com">Mentimeter!</a>
                </p>
                <h6><b>DevOps for Data</b></h6>
                <p>The fields of Data Engineering and Data Science are both quite young
                    without processes and practices that have been battle tested.
                    I am interested in how can I spend as little time as possible on fixing
                    leaking pipelines and instead focus on delivering value to the business,
                    which is what I am getting paid for. How can automate testing of data, pipeline
                    deployment and so on? Then the other question is how can we build applications
                    that are easily built, configured and deployed?
                </p>
                <p>Yeah so basically:</p>
                <div class="row">
                    <div class="col-md-6 d-flex justify-content-center">
                        <img src="/assets/automate.jpg" height="200px">
                    </div>
                    <div class="col-md-6 d-flex justify-content-center">
                        <img src="/assets/dockerize.jpg" height="200px">
                    </div>
                </div>
                <h6><b><del>Arch</del> Linux</b></h6>
                <del>I run Arch Linux on my laptop. It's great!</del> It took 10 months until
                I bricked my Arch Linux distro and I now use
                <a href="https://pop.system76.com/">Pop!_OS</a>
                instead. I think desktop linux gets a bad rap and it can be very easy to use
                and user friendly if you choose the right distro, like Pop!_OS.
                I also think that shell is an amazing tool, and I like making
                shell scripts to automate anything that would take more than 5 minutes
                to do manually.
                <div class="row">
                    <div class="col-md-12 justify-content-center linux">
                        <img src="/assets/linux.png" class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="projects">
                <h4>Projects</h4>
                <p>A showcase of interesting projects I have been working on</p>
                <h5 id="homeserver"><b>Home Cluster</b></h5>
                <p>
                    I am currently running a small cluster of Raspberry Pis at home,
                    which dutifully sent this website to you. I am using
                    <a href="https://traefik.io/" target="_blank">traefik</a>
                    as my reverse proxy. Traefik routes my subdomains to exposed docker containers
                    and will automatically enable https on them too. This makes it
                    really easy to spin up new services! It is as simple as just defining
                    a label for which address the docker container should listen to.
                    In the below picture you can see an overview of the architecture,
                    and how the different containers interact with each other.
                </p>
                <p>Before I had a draw io diagram for this, but now I generate this picture with
                    code using <a href="https://diagrams.mingrammer.com/">Diagrams as Code</a>.
                    The code for the below picture can be seen <a
                        href="https://github.com/aleda145/resumewebpage/blob/master/src/diagram/diagram.py">
                        in my repo</a>.
                </p>
                <div class="col-md-12 justify-content-center architecture">
                    <img src="/assets/home_server_architecture.png" class="img-fluid">
                </div>
                <h5 id="monitoring"><b>Raspberry Pi Health</b></h5>
                <p>
                    Monitoring the health of my infrastrucuture is critical to know if I should rebalance
                    some code or distribute services differently on my cluster. I have a stats gatherer
                    written in python that monitors the health of the CPU, hard-drive, RAM and the temperature.
                    Then I also have a shell script that checks my network speed every ten minutes.
                    The data is then sent to <a href="timescaledb.com">Timescaledb</a>, which is a
                    database optimized for time series data.

                    Since the python script runs in a docker container I noticed some significant slowdowns
                    for my speed measurement if it was running there. I don't know if this is because of docker
                    or python, so instead I opted for just doing a simple shell script scheduled with crontab.
                </p>
                <p>
                    For live update on the health please checkout <a
                        href="https://grafana.dahl.dev">grafana.dahl.dev</a>
                </p>
                <p>
                    For a summary on the different months see my superset dashboard that aggregates some of the health
                    data see: <a href="https://superset.dahl.dev/dashboards/2">superset.dahl.dev</a>
                </p>
                <h5 id="webpipeline"><b>Web pipeline</b></h5>
                <p>This website is automatically deployed to the server when new commits are pushed
                    to the master branch. The website is an angular application, which can be viewed
                    on <a href="https://github.com/aleda145/resumewebpage">github</a>. I have set up
                    <a href="https://www.drone.io/">drone</a>, which is a self hosted CI/CD service that will
                    get a hook from github whenever a new commit is pushed to master. The drone runner
                    can be viewed on
                    <a href="https://drone.dahl.dev/aleda145/resumewebpage/">drone.dahl.dev/aleda145/resumewebpage/</a>.
                    The runner will build the supplied Dockerfile in the repo, and sent it up to my
                    <a href="https://hub.docker.com/u/aleda145">dockerhub account</a>.
                    Then I have <a href="https://github.com/containrrr/watchtower">Watchtower</a> running in a
                    container. Watchtower will poll the docker image every 30 seconds,
                    and if it notices
                    a change in docker images, it will pull the image, create a new container and
                    start the service. This will result in a downtime for the website of around 1 second,
                    which is acceptable since I get very little traffic :)
                </p>
                <div class="col-md-12 justify-content-center">
                    <img src="/assets/web_pipeline.png" class="img-fluid">
                </div>
                <h5 id="LARM"><b>Head of IT at LARM</b></h5>
                <p>I was head of IT for <a href="https://larm.lintek.liu.se/">LARM</a> in 2019.
                    I did some cool things to make the fair run as smoothly as possible.
                    I launched the usage of RFID readers for checking into events. The previous process
                    was students walking up to a desk, stating their name and were checked off in an
                    Excel sheet. This was a tedious process that took at least 10 minutes when you have
                    300 students going to an event. My implementation meant that the students could check
                    themselves in with their student card, and they were automatically registered in the database.
                    Taking the check in time down to less than 5 minutes.
                </p>
                <h5 id="enrollment"><b>Superset Dashboard for Swedish Higher Education Enrollment</b></h5>
                <p>
                    I was curious exploring the statistics data for enrollment, questions like,
                    how has the gender ratio changed for higher education in the last 20 years?
                    or, what is the normal age for a student that is enrolled in an engineering degree?

                    I exported the data from UKÄ (link) and inserted it into a Postgres database.
                    The postgres database was connected to Apache Superset, an Open Source
                    dashboarding tool. Then the data crunched there.

                    Check it out at <a href="https://superset.dahl.dev/superset/dashboard/1/">superset.dahl.dev</a>!
                    Maybe you can find some interesting insight yourself?
                </p>
                <div class="interests">
                    <h4>Interests</h4>
                    <h6 id="bike"><b>Bicycle Touring</b></h6>
                    <p>I rode my bicycle from Connecticut, USA to San Jose, Costa Rica in 2016-2017.
                        That was pretty cool! It was approximately 10000km. My bike computer broke after
                        7000km and southern Mexico is not the best place to replace a bike computer,
                        so I can't know for sure!
                    </p>
                    <p>This was my route:</p>
                    <div class="col-md-12 justify-content-center">
                        <img src="/assets/bike.png" class="img-fluid">
                    </div>
                    <h6><b>Fermentation and brewing</b></h6>
                    <p>I like fermenting things. I almost always have homemade kimchi in my fridge.
                        Then I also do some homebrewing. Last I did Sake, japanese rice wine. It was really cool because
                        it introduced me to koji, a japanese fungus/mold that transforms the starch in
                        the rice to sugar, which the yeast can then eat to produce alcohol.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>